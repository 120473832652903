export default (to, from, next) => {
  if (
    localStorage.getItem('userInfo') != null &&
    localStorage.getItem('userInfo').length > 0
  ) {
    next()
  } else {
    localStorage.removeItem('userInfo')
    console.log( '[authenticate] from --->', from )
    console.log( '[authenticate] to --->', to )
    // next('/app/sessions/sign-in')
    if( to.path==='/dispatchMgmt' || to.path==='/request' || to.path==='/result' || to.path==='/settlement' ) {
      next({name: 'login', params: {toPath: to.path}})
    }else{
      next('/app/sessions/sign-in')
    }
  }
}
