import Noty from 'noty'

export default {
  showMessage(message, type = 'info') {
    new Noty({
      type: type,
      theme: 'relax',
      closeWith: ['click', 'button'],
      text: message,
      timeout: 5000
    }).show()
  },
  showError(errorMessage) {
    this.showMessage(errorMessage, 'error')
  },
  closeAll() {
    Noty.closeAll()
  }
}
