import axios from 'axios'
import io from 'socket.io-client'
import api from '@/services/api'
import notify from '@/utils/notify'

export default {
  state: {
    appBarTitle: 'ERS Mobile 빈 페이지',
    geoLocation: null,
    ws: null, // web socket
    newRequest: null, // 새로 들어온 request
    mobileMessages: [],
    ersCount: { agent: {}, ers: {} },
  },
  getters: {
    appBarTitle: state => state.appBarTitle,
    geoLocation: state => state.geoLocation,
    ws: state => state.ws,
    newRequest: state => state.newRequest,
    mobileMessages: state => state.mobileMessages,
    ersCount: state => state.ersCount
  },
  mutations: {
    set (state, [variable, value]) {
      state[variable] = value
    },
    setAppBarTitle(state, data) {
      state.appBarTitle = data
    },
    setSocket(state, socket) {
      state.ws = socket
    },
    setNewRequest(state, request) {
      state.newRequest = request
    },
    setMobileMessages(state, messages) {
      state.mobileMessages = messages
    },
    pushMobileMessage(state, message) {
      state.mobileMessages.splice(0, 0, message)
    },
    clearMobileMessages(state) {
      state.mobileMessages = []
    },
    setErsCount(state, count) {
      state.ersCount = count
    }
  },
  actions: {
    connectWS({ commit, getters, dispatch }) {
      const centerCode = getters.loggedInUser.agent.agency.centerCode
      console.log('connecting websocket - [' + centerCode + ']')
      let ws = null
      try {
        ws = io('/' + centerCode, {
          extraHeaders: { 'x-access-token': getters.loggedInUser.accessToken },
          path: '/ws/socket.io',
          secure: true,
          reconnection: true,
          reconnectionAttempts: 100,
          reconnectionDelay: 3000
        })
      } catch (e) {
        console.log(e)
      }
      ws.on('connect', () => {
        console.log('ws connect - ' + ws.id)
      })
      ws.on('time', (time) => {
        console.log(time)
      })
      ws.on('disconnect', () => {
        console.log('ws disconnect')
        // if (getters.loggedInUser != null) { ws.connect() }
      })
      ws.on('ers-message', (arg) => {
        console.log('ers message - ' + JSON.stringify(arg))
        dispatch('newRequest', arg)
      })
      ws.on('message', (arg) => {
        console.log('message - ' + JSON.stringify(arg))
        commit('pushMobileMessage', arg)
        notify.showMessage('새로운 메시지가 있습니다 : ' + arg.message)
      })
      commit('setSocket', ws)
    },
    setCurrentGeoLocation({ commit }) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(pos => {
          console.log('location [' + pos.coords.latitude + ', ' + pos.coords.longitude + ']')
          commit('set', ['geoLocation', pos])
          api.setAgentLocation(pos.coords.longitude, pos.coords.latitude)
        }, err => {
          alert('위치정보 접근 허용을 해주셔야 합니다.[' + err.message + ']')
        },
          {
            enableHighAccuracy: false,
            timeout: 5 * 1000
          })
      } else {
        alert('위치정보 접근 허용을 해주셔야 합니다.')
      }
    },
    newRequest({ commit }, req) {
      commit('setNewRequest', req)
      notify.showMessage('새로운 요청이 있습니다.')
    }
  }
}
