<template>
  <v-app>
    <component :is="getThemeMode.layout" />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      layoutName: '',
    }
  },
  computed: {
    ...mapGetters(['getThemeMode']),
  },
  // mounted() {
  //   if (this.$route.params.layout) {
  //     this.changeThemeLayout(this.$route.params.layout);
  //   }
  // },
  methods: {
    ...mapActions(['changeThemeLayout']),
  },
}
</script>

<style>
.v-text-field input {
  font-size: 14px;
}

.v-input .v-label {
  font-size: 14px;
}
</style>
