import ajax from './ajax'
import axios from 'axios'

export default {
  getAgreement() {
    return ajax.get('/terms/agreement')
  },
  getUserAgreement(termsId) {
    return ajax.get(`/terms/user-agreement/${termsId}`)
  },

  setUserAgreement( param ) {
    return ajax.post(`/terms/agree`, param)
  },

  // #api-geoAPI-setAgentLocation
  setAgentLocation(longitude, latitude) {
    return ajax.post('/geo/location', { latitude: latitude, longitude: longitude })
  },
  // #api-ers-request-getErsRequestByQuery
  getErsRequestByQuery(hours, status) {
    return ajax.get('/ers-req/query', { hours: hours, status: status })
  },

  getErsRequestById(ersId) {
    return ajax.get('/ers-req/' + ersId)
  },
  // #api-agent-setAgentRequestToCenter
  setAgentRequestToCenter(ersId, type, message) {
    return ajax.post('/agent/request', { ersRequest: ersId, arqType: type, message: message })
  },
  // #api-agent-getAgentsWithStatus
  getAgentsWithStatus() {
    return ajax.get('/agent/status')
  },
  // #api-ers-request-putErsAssignByController
  putErsAssignByController(ersId, agentId) {
    return ajax.put('/ers-req/agent/' + ersId, { agentId: agentId })
  },
  // #api-ers-request-putErsSetContactTo
  putErsSetContactTo(ersId, callee, message = null) {
    return ajax.put('/ers-req/calling/' + callee, { ersReqId: ersId, message: message })
  },
  // #api-dashboard-getDashboardCount
  dashBoardCount() {
    return ajax.get('/dashboard/count')
  },
  // #api-dispatch-getDispatchById
  getDispatchById(id) {
    return ajax.get('/dispatch/' + id)
  },
  // #api-vehicle-getVehicles
  getVehicles() {
    return ajax.get('/vehicle')
  },
  // #api-vehicle-postVehicle 차량 등록
  postVehicle(item) {
    return ajax.post('/vehicle', item)
  },
  // 수정
  putVehicle(id, item) {
    return ajax.put('/vehicle/' + id, item)
  },
  deleteVehicle(id) {
    return ajax.delete('/vehicle/' + id)
  },
  // #api-message-getMessageReceives
  getMessageReceives() {
    return ajax.get('/message')
  },
  // #api-dispatch-postDispatchDeparture 출동 등록
  dispatchDeparture(ersId, vehicleId, longitude, latitude) {
    return ajax.post('/dispatch', { ersRequest: ersId, vehicle: vehicleId, location: { longitude: longitude, latitude: latitude } })
  },
  // 도착 등록
  dispatchArrival(ersId, vehicleId, longitude, latitude) {
    return ajax.post('/dispatch/arrival', { ersRequest: ersId, vehicle: vehicleId, location: { longitude: longitude, latitude: latitude } })
  },
  // 코드 조회
  listCode(type = 'task', group = 'extra') {
    return ajax.get('/code/' + type + '/' + group)
  },
  listCodeExtra() {
    return this.listCode('cost', 'extra')
  },
  listCodeTask() {
    return ajax.get('/code/task')
  },
  // 코드 그룹 조회
  listGroupCode(groupCode) {
    return ajax.get('/code/' + groupCode)
  },
  // #api-partner-getPartnersByQuery 파트너 조회
  getPartnerByQuery(longitude, latitude, name = null, dist = 10000, type = 'tow') {
    return ajax.get('/partner/query', {
      type: type,
      dist: dist,
      x: longitude,
      y: latitude,
      name: name
    })
  },
  // #api-partner-getPartnersNearby 파트너 입고지 조회
  getPartnersNearby(longitude, latitude, custCode, name, dist = 10000) {
    return ajax.get('/partner/nearby', {
      x: longitude,
      y: latitude,
      custCode: custCode,
      name: name,
      dist: dist
    })
  },
  // #api-task-postTask 작업 등록
  postTask(taskItem) {
    return ajax.post('/task', taskItem)
  },
  // 작업 수정
  putTask(taskItem) {
    return ajax.put('/task/' + taskItem._id, taskItem)
  },
  // task 조회
  getTask(taskId) {
    return ajax.get('/task/' + taskId)
  },
  // #api-proof-getProofById 사진 업로드 상태 조회
  getProofById(taskId) {
    return ajax.get('/proof/' + taskId)
  },
  // #api-proof-postProof 사진 전송
  postProof(taskId, images, proofType, isAfter = 'false', viewpoint = 'none', onUploadProgress = null, cancelSource = null) {
    return ajax.uploadPost('/proof', { taskId: taskId, proofType: proofType, isAfter: isAfter, viewpoint: viewpoint }, images, onUploadProgress, cancelSource)
  },
  // #api-proof-deleteProof 사진 삭제
  deleteProof(proofId) {
    return ajax.delete('/proof/' + proofId)
  },
  // #api-proof-deleteProofByTaskId 전체 사진 삭제
  deleteProofByTaskId(taskId) {
    return ajax.delete('/proof/task/' + taskId)
  },
  // #api-task-getTasksByQuery 춮동 결과
  getTaskByQuery(ersDts, hours, custCode, proofed = null) {
    return ajax.get('/task/query', { ersDts: ersDts, hours: hours, custCode: custCode, proofed: proofed })
  },
  // #api-customer-getCustomerByCenter 고객 조회
  getCustomerByCenter() {
    return ajax.get('/customer/center')
  },
  // #api-agent-getAgentsByAgencyCode
  getAgentsByAgencyCode(agencyCode) {
    return ajax.get('/agent/agency-code/' + agencyCode)
  },
  // #api-agent-getAgents 동일 agency 소속 요원 조회
  getAgents() {
    return ajax.get('/agent')
  },
  // #api-agent-getAgentsStatusByWork
  getAgentsStatusByWork() {
    return ajax.get('/agent/work-status')
  },
  // #api-agent-postAgent 요원 등록
  registerAgent(item) {
    return ajax.post('/agent/register', item)
  },
  // #api-agent-deleteAgent 요원 삭제
  deleteAgent(id) {
    return ajax.delete('/agent/' + id)
  },
  // 요원 수정
  putAgent(id, item) {
    return ajax.put('/agent/' + id, item)
  },
  // #api-agent-putAgentStatus 근무 상태 변경
  putAgentStatus(id, status) {
    return ajax.put('/agent/status/' + id, { status: status })
  },
  putAgentController(id, toBe = false) {
    return ajax.put('/agent/status/' + id, { controller: toBe })
  },
  // #api-agency-getAgency 가맹점 조회
  getAgency() {
    return ajax.get('/agency')
  },
  getAgencyByCode(id) {
    return ajax.get('/agency/' + id)
  },
  // #api-agency-putAgency 가맹점 수정
  putAgency(id, item) {
    return ajax.put('/agency/' + id, item)
  },
  // #api-auth-setPwdChange ( currPwd, newPwd, rePwd }
  setPwdChange(item) {
    return ajax.post('/auth/pwd-change', item)
  },
  // #api-settlement-getStmtByMonth 정산서 조회
  getStmtByMonth(month) {
    return ajax.get('/settlement/stmt/' + month)
  },
  async downloadStmt(month) {
    return await axios.get('/settlement/download/' + month, { responseType: 'blob' })
  },
  // #api-settlement-setConfirmStatement 정산서 확인
  setConfirmStatement(list) {
    return ajax.post('/settlement/confirm', { stmtIds: list })
  },
  // #api-auth-setPwdChangeOnLogin
  pwdChangeOnLogin(item, id, type, errorHandler) {
    item.userId = id
    item.changeType = type
    return ajax.post('/auth/pwd-change-on-login', item, errorHandler)
  },
  // #api-auth-initPassword 사업주용 패스워드 변경
  initPassword(id) {
    return ajax.post('/auth/pwd-reset', { userId: id })
  }
}
