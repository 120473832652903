import axios from 'axios'
import serverService from '@/services/server'
import moment from 'moment'
import notify from '@/utils/notify'

export default {
  state: {
    loggedInUser:
      localStorage.getItem('userInfo') != null
        ? JSON.parse(localStorage.getItem('userInfo'))
        : null,
    loading: false,
    error: null,
    agreed: false,
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    loading: state => state.loading,
    agreed: state => state.agreed,
    error: state => state.error,
    isController: state => state.loggedInUser && state.loggedInUser.agent && state.loggedInUser.agent.isController,
    isPresident: state => state.loggedInUser && state.loggedInUser.user && state.loggedInUser.user.role === 'AP',
    role: state => state.loggedInUser && state.loggedInUser.user && state.loggedInUser.user.role,
  },
  mutations: {
    setUser(state, data) {
      state.loggedInUser = data
      state.loading = false
      state.error = null
    },
    setAgreed(state,data){
      state.agreed = data
    },
    setLogout(state) {
      state.loggedInUser = null
      state.loading = false
      state.error = null
      state.agreed = false
    },
    setLoading(state, data) {
      state.loading = data
      state.error = null
    },
    setError(state, data) {
      state.error = data
      state.loggedInUser = null
      state.loading = false
    },
    clearError(state) {
      state.error = null
    }
  },
  actions: {
    login({ commit, dispatch }, data) {
      commit('clearError')
      commit('setLoading', true)
      serverService.login(data).then(r => {
        switch (r.code) {
          case 200 :
          case 201 :
          case 209 : {
            const userInfo = r.result
            userInfo.loginTime = moment()
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
            if (data.saveId) {
              localStorage.setItem('savedId', data.loginId)
            } else {
              localStorage.removeItem('savedId')
            }
            if( data.savePw ){
              localStorage.setItem('savedPw', data.loginPwd)
            }else{
              localStorage.removeItem('savedPw')
            }

            axios.defaults.headers.common['x-access-token'] = userInfo.accessToken
            commit('setUser', userInfo)
            dispatch('connectWS')
            if (r.code === 209 || r.code === 201) {
              notify.showError('비밀번호 변경 페이지로 이동 합니다.')
              this.$router.push('/settings/reset').then(() => { })
            }
            break
          }
          case 290 :
            commit('setError', '중복된 로그인 입니다. 문의 바랍니다.')
            break
          case 299 :
            commit('setError', '중복된 로그인 이며, 비밀번호가 만료 되었습니다. 문의 바랍니다.')
            break
          default :
            commit('setError', '알수없는 에러 (' + r.code + ') 문의 바랍니다.')
            break
        }
      }).catch(() => {
        commit('setError', '아이이와 패스워드를 확인해 주십시오')
      })
    },

    signUserUp({ commit }, data) {
      // do not sign up
    },

    signOut({ commit, getters }) {
      serverService.logout()
      localStorage.removeItem('userInfo')
      axios.defaults.headers.common['x-access-token'] = undefined
      commit('setLogout')
      if (getters.ws != null) {
        getters.ws.close()
        commit('setSocket', null)
      }
    }
  }
}
