import axios from 'axios'
import notify from '@/utils/notify'
import router from '@/router'
import store from "@/store";

export default {
  /** Vuetify Data Table sorting options to Spring boot PageRequest */
  optionsToParams(options, paramMap = null, sortMap = null) {
    const { sortBy, sortDesc, page, itemsPerPage } = options

    const params = {
      page: page - 1,
      size: itemsPerPage,
    }

    if (sortBy.length === 1 && sortDesc.length === 1) {
      const sortName = (sortMap != null && sortMap[sortBy[0]] != null) ? sortMap[sortBy[0]] : sortBy[0]
      params.sort = sortName + ',' + ((sortDesc[0]) ? 'desc' : 'asc')
    }
    if (paramMap != null) {
      for (const [k, v] of Object.entries(paramMap)) {
        if (v != null) { params[k] = v }
      }
    }
    return params
  },

  call (method, url, params, payload, errorHandler) {
    return new Promise((resolve, reject) => {
      axios({
        method: method,
        url: url,
        params: params,
        data: payload
      }).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        if (errorHandler != null) {
          errorHandler(error, reject)
        } else {
          reject(error)
        }
      })
    })
  },
  get (url, params = null, errorHandler = this.defaultHandleError) {
    return this.call('get', url, params, null, errorHandler)
  },
  post (url, payload, errorHandler = this.defaultHandleError) {
    return this.call('post', url, null, payload, errorHandler)
  },
  put (url, payload, errorHandler = this.defaultHandleError) {
    return this.call('put', url, null, payload, errorHandler)
  },
  delete (url, params = null, errorHandler = this.defaultHandleError) {
    return this.call('delete', url, params, null, errorHandler)
  },
  /*
    files = file upload object 의 array 이면 part name 'file' 로 업로드 되며, 파트이름을 각각 주려면
    files[].part = 'partName'
    files[].content = file upload object 를 주면 된다.
   */
  upload (method, url, payload, files, onUploadProgress, cancelToken, errorHandler) {
    const formData = new FormData()
    if (payload !== undefined && payload != null) {
      Object.keys(payload).forEach(e => {
        formData.append(e, payload[e])
      })
    }
    if (files !== undefined && files != null) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].part !== undefined && files[i].content !== undefined) {
          formData.append(files[i].part, files[i].content)
        } else {
          formData.append('images', files[i])
        }
      }
    }
    return new Promise((resolve, reject) => {
      axios({
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        method: method,
        url: url,
        data: formData,
        onUploadProgress: onUploadProgress,
        cancelToken: cancelToken
      }).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        errorHandler(error, reject)
      })
    })
  },

  uploadPost (url, payload, files, onUploadProgress, cancelSource = null, errorHandler = this.defaultHandleError) {
    return this.upload('post', url, payload, files, onUploadProgress, cancelSource === null ? null : cancelSource.token, errorHandler)
  },

  uploadPut (url, payload, files, onUploadProgress, cancelSource = null, errorHandler = this.defaultHandleError) {
    return this.upload('put', url, payload, files, onUploadProgress, cancelSource === null ? null : cancelSource.token, errorHandler)
  },

  newCancelSource() {
    return axios.CancelToken.source()
  },

  defaultHandleError (error, reject) {
    if (axios.isCancel(error)) {
      notify.showError(error.message)
      reject(error)
    } else if (error.response) {
      const status = error.response.status
      const data = error.response.data
      switch (status) {
        case 401 :
        case 403 :
          router.push('/app/sessions/sign-in').then(() => {})
          return
        case 404 :
          notify.showError('찾으시는 내용이 없습니다.'); break
        case 400 :
          notify.showError('[' + data.code + '] ' + data.message); break
        default :
          notify.showError('status : ' + status + ', data = ' + data.message)
      }
    } else if (error.request) {
      notify.showError('서버의 응답이 없습니다.')
    } else {
      notify.showError(error.message)
    }
    reject(error)
  }
}
